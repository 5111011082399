import React from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import FlightMap from './FlightMap';
import Form from 'react-bootstrap/Form';

export default function FlightMapContainer() {
  const mapBounds = [
    [-20000, -20000],
    [20000, 20000],
  ];

  return React.createElement(
    MapContainer,
    {
      center: [28, -81],
      className: 'map',
      zoom: 5,
      minZoom: 2,
      maxZoom: 18,
      style: {width: '100vw', height: '100vh', zIndex: 0},
      noWrap: true,
      maxBounds: mapBounds,
      maxBoundsViscosity: 1.0,
      wheelPxPerZoomLevel: 300,
    },
    [
      React.createElement(TileLayer, {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
        key: 'tileLayer',
        style: {zIndex: 0},
      }),
      React.createElement(FlightMap, {key: 'flightMap'}), // Assuming you have defined the FlightMap component
    ],
  );
}
