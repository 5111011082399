import ReactDOM from 'react-dom/server';
import React, {useState, useEffect} from 'react';
import {Marker, Popup, Polyline, map} from 'react-leaflet';
import {useMapEvents} from 'react-leaflet/hooks';
import 'leaflet/dist/leaflet.css';
import {IoIosAirplane} from 'react-icons/io';
import {divIcon} from 'leaflet';
import axios from 'axios';
import Loading from './Loading';

//import testdata from './testdata.json';

export default function FlightMap() {
  const [flights, setFlights] = useState([]);
  const [flightsDisplayed, setFlightsDisplayed] = useState([]);
  const [currentBounds, setCurrentBounds] = useState(null);
  const [selected, setSelected] = useState('All');
  const [airlines, setAirlines] = useState([]);
  const [path, setPath] = useState([]);
  const [loading, setLoading] = useState(true);

  const depIcon = new divIcon({
    className: 'custom-div-icon',
    // style: {
    //   'margin-left': '-17px',
    // },
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    html: "<div style='background-color:green;' class='marker-pin'></div><i class='fa fa-plane-departure flight'></i>",
  });

  const arrIcon = new divIcon({
    className: 'custom-div-icon',
    style: {
      'margin-left': '-17px',
    },
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    html: "<div style='background-color:red;' class='marker-pin'></div><i class='fa fa-plane-arrival flight'></i>",
  });

  const map = useMapEvents({
    moveend: () => {
      setCurrentBounds(map.getBounds());
    },
  });

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const fetchFlights = async () => {
    try {
      let f = [];
      let airlines = [];
      const response = await axios.get('https://va-flighttracker-backen-d.vercel.app/get_flights');
      let data = response.data;
      for (const [key, value] of Object.entries(data)) {
        f = f.concat(value);
        airlines.push(key);
      }
      setAirlines(airlines);
      setFlights(f);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchFlights();
    setCurrentBounds(map.getBounds());
    const intervalId = setInterval(fetchFlights, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let f = [];
    if (flights?.length) {
      const flightsWithinBounds = flights.filter((flight) => {
        if (!currentBounds || !flight[0] || !flight[1] || !flight[10]) {
          return false;
        }
        if (selected === 'All') {
          return currentBounds.contains([flight[0], flight[1]]);
        } else {
          return currentBounds.contains([flight[0], flight[1]]) && flight[10] === selected;
        }
      });
      setFlightsDisplayed(flightsWithinBounds);
      console.log(loading);
    }
  }, [currentBounds, flights, selected]);

  // let a = '';
  // if (loading) {
  //   a = <Loading />;
  // }

  let renderSelect = [];
  airlines.forEach((airline) => {
    renderSelect.push(React.createElement('option', {value: airline}, airline));
  });
  return React.createElement(
    React.Fragment,
    null,
    // a,
    React.createElement(
      'select',
      {
        className: 'select',
        onChange: (e) => handleChange(e),
      },
      React.createElement('option', {value: 'All'}, 'All airlines'),
      renderSelect,
    ),
    flightsDisplayed.map((flight) => {
      const key = flight[4];
      const position = [flight[0], flight[1]];
      const flightIcon = new divIcon({
        className: 'flight-icon',
        html: ReactDOM.renderToString(
          React.createElement(IoIosAirplane, {
            size: '40',
            color: '#CF9603',
            style: {
              '-moz-transform': `rotate(${flight[3]}deg)`,
              '-webkit-transform': `rotate(${flight[3]}deg)`,
              '-o-transform': `rotate(${flight[3]}deg)`,
              '-ms-transform': `rotate(${flight[3]}deg)`,
              zIndex: 1000,
            },
            transform: `rotate(${flight[3]})`,
          }),
        ),
      });
      const popupContent = React.createElement(
        'div',
        {className: 'pop'},
        React.createElement('p', null, React.createElement('strong', null, 'ICAO24: '), flight[4]),
        React.createElement(
          'p',
          null,
          React.createElement('strong', null, 'Callsign: '),
          flight[5],
        ),
        React.createElement('p', null, React.createElement('strong', null, 'Origin: '), flight[2]),
      );
      return React.createElement(
        Marker,
        {
          key: key,
          position: position,
          icon: flightIcon,
          eventHandlers: {
            click: () => {
              map.flyTo(position, 5);
              let newPaths = [];
              let positions = [[flight[7], flight[6]]];
              console.log(flight);
              if (flight[11]) {
                flight[11].forEach((waypoint) => {
                  positions.push(waypoint);
                });
              }
              positions.push([flight[9], flight[8]]);
              let newPath = React.createElement(Polyline, {
                className: 'leaflet-popup-content',
                color: 'red',
                weight: 3,
                opacity: 0.5,
                smoothFactor: 1,
                // positions: [
                //   [flight[7], flight[6]],
                //   [flight[9], flight[8]],
                // ],
                positions: positions,
              });
              let dep = React.createElement(Marker, {
                position: [flight[7], flight[6]],
                icon: depIcon,
              });
              let arr = React.createElement(Marker, {
                position: [flight[9], flight[8]],
                icon: arrIcon,
              });
              newPaths = [newPath, dep, arr, popupContent];
              setPath(newPaths);
            },
            popupclose: () => {
              setPath([]);
            },
          },
        },
        React.createElement(
          Popup,
          {
            className: 'popup',
          },
          null,
        ),
        path,
      );
    }),
  );
}
